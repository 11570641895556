import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../components/GA'
import { Grid } from '../../atoms/Grid'
import { GridLegacyCards } from '../../molecules/GridLegacyCards'
import { Montserrat, size } from '../../constants'
import { PsychologistMiniCardAnexity } from '../PsychologistMiniCard'
import { color } from '../../styles/vars/colors'
import { isBrowser } from '../../atoms/root'
import { randomChoice } from '../../atoms/randomChoice'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const ButtonToForm = styled(({ className }) => (
  <div className={className}>
    <Button.NewPrimary
      transparent
      href="/wizard/"
      onClick={GAEvent.openSemeinyiPageFirstScreen}
      size="large"
      type="link"
    >
      Подобрать психолога
    </Button.NewPrimary>
  </div>
))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    gap: 16px;

    & ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

const TitlePsychologist = styled.p`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: ${(props) =>
    props.bold ? '700' : props.semiBold ? '500' : '400'};
  font-size: 32px;
  line-height: 40px;
  margin: 0;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const PsychologistsWithKognitivnii = styled(({ className, data }) => {
  const windowWidth = useSelector(selectDeviceSize)
  const psychologists = useMemo(
    () => randomChoice(data.psychologists.edges.map(({ node }) => node)),
    [data.psychologist]
  )

  const countByWindowWidth = useMemo(
    () => (windowWidth < 1024 ? 2 : 3),
    [windowWidth]
  )

  const psychologistSliced = useMemo(
    () => (isBrowser ? psychologists.slice(0, countByWindowWidth) : []),
    [countByWindowWidth]
  )

  return (
    <section className={className}>
      <Grid>
        <GridLegacyCards>
          {psychologistSliced.map((psychologist) => (
            <PsychologistMiniCardAnexity
              key={psychologist.id}
              psychologist={psychologist}
            />
          ))}
        </GridLegacyCards>
        <ButtonToForm />
      </Grid>
    </section>
  )
})`
  background: #dfebf9;
  padding-top: 72px;
  padding-bottom: 96px;

  @media (max-width: ${size.lg}) {
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  & ${PsychologistMiniCardAnexity} {
    background: ${color.background};
    height: 468px;
  }

  & ${TitlePsychologist} {
    margin-bottom: 36px;

    @media (max-width: ${size.md}) {
      margin-bottom: 16px;
    }
  }

  & ${GridLegacyCards} {
    margin-bottom: 56px;

    @media (max-width: ${size.md}) {
      margin-bottom: 40px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 32px;
    }
  }
`
