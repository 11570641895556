import React from 'react'
import {
  BlockImage,
  CardContainer,
  Content,
  TextWrapper,
  Wrapper
} from './KognitivniiHelps.styles'
import { Title } from '../../../../youtalk-storybook/src/ui'

export const KognitivniiHelps = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <BlockImage />
          <Title.H2>
            Оказание психологической помощи когнитивным терапевтом
          </Title.H2>
          <TextWrapper>
            Когнитивный терапевт помогает клиенту отследить ошибки мышления и
            негативные автоматические мысли, которые приводят к тому, что мы
            начинаем избегать желаемого или своим поведением усугляем проблемы
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
