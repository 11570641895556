import React from 'react'
import {
  BlockImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './HowToFindKognitivnii.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const HowToFindKognitivnii = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Как найти и выбрать психолога специалиста по КПТ
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Для корректной работы с мышлением и поведением человека,
                специалист должен получить высшее психологическое образование и
                пройти грамотное обучение когнитивно-поведенческой терапии.
              </Text.Large>
              <Text.Large semiBold>
                В YouTalk мы проверяем дипломы специалистов, их опыт и умение
                работать даже с уязвимыми группами людей.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Человек пришел на собеседование, чтобы устроиться на работу" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
