import React from 'react'
import {
  BlockContainer,
  BlockFiveImage,
  BlockFourImage,
  BlockOneImage,
  BlockSevenImage,
  BlockSixImage,
  BlockThreeImage,
  BlockTwoImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  TextH3styles,
  Wrapper
} from './WhoCanHelpsKognitivnii.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const WhoCanHelpsKognitivnii = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <BlockContainer>
            <InfoContainer>
              <Title.H2>
                Кому может помочь когнитивно-поведенческая психотерапия
              </Title.H2>
              <TextH3styles>Пример случая (вымышленный)</TextH3styles>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Можно рассмотреть механизм работы КПТ на примере
                  обсессивно-компульсивного расстройства (ОКР).
                </Text.Large>
                <Text.Large semiBold>
                  ОКР проявляется двумя симптомами: это навязчивые тревожные
                  мысли (обсессии) и компульсии, т.е. поведенческие
                  повторяющиеся действия, так называемые ритуалы, направленные
                  на снижение тревоги, внутреннего напряжения и дискомфорта,
                  вызванного этими навязчивыми мыслями.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockOneImage alt="Кому может помочь когнитивно-поведенческая психотерапия" />
            </ImageWrapper>
          </BlockContainer>

          <BlockContainer>
            <ImageWrapper>
              <BlockTwoImage alt="Человек боится заразится какой-то болезнью" />
            </ImageWrapper>
            <InfoContainer>
              <TextH3styles>
                Например, человек боится заразится какой-то болезнью. Он
                касается дверной ручки.
              </TextH3styles>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Этот триггер запускает навязчивую мысль «на ручке могут быть
                  микробы и я заражусь». Мысль сама по себе вызывает сильную
                  тревогу. Для того чтобы совладать с тревогой человек прибегает
                  к особым компульсивным ритуалам, например, моет руки. Однако
                  ритуал приносит только краткосрочное облегчение. Затем тревога
                  нарастает по новой, так как причина, а именно мысль «мир
                  опасен, он может меня заразить» никуда не девается.{' '}
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>

          <BlockContainer>
            <InfoContainer>
              <TextH3styles>
                К тревожным мыслям добавляется и то, что зачастую люди,
                страдающие ОКР, свои ритуалы оценивают, как что-то выходящее за
                рамки нормативного действия.
              </TextH3styles>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Они замечают, что большинство людей вокруг не ведут себя
                  подобным образом и не заболевают. Они это видят, но ничего
                  поделать не могут с внутренним напряжением и огромной
                  потребностью с ней справится.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockThreeImage alt="Ритуалы оценивают, как что-то выходящее за рамки нормативного действия" />
            </ImageWrapper>
          </BlockContainer>

          <BlockContainer>
            <ImageWrapper>
              <BlockFourImage alt="Все это усиливает тревожное состояние" />
            </ImageWrapper>
            <InfoContainer>
              <TextH3styles>
                Все это усиливает тревожное состояние, так как добавляет мысли о
                самом себе
              </TextH3styles>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Например «со мной что-то не так. Следовательно, возникает
                  такой порочный круг между обсессивной мыслью и компенсаторным
                  действием: чем больше напряжения от одного цикла, тем быстрее
                  и интенсивнее начинается новый. Формируется устойчивая
                  нейронная связь между определенными областями головного мозга,
                  отвечающих за оценку ситуации как угрожающей и отвечающих за
                  устранение угрозы.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>

          <BlockContainer>
            <InfoContainer>
              <TextH3styles>
                Проще говоря, тот самый порочный круг, о котором мы говорили
                выше, формируется следующим образом:
              </TextH3styles>
              <ListWrapper>
                <ListItem>
                  <Text.Large semiBold>
                    Некая мысль (например, «я могу заразиться»), которая сама по
                    себе является естественной для человека, желающего продлить
                    свою жизнь, вызывает также естественную эмоцию – страх;
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Далее поведение (мытье рук), направленное на совладание с
                    угрозой заражения, помогает снизить страх с помощью смывания
                    грязи.{' '}
                  </Text.Large>
                </ListItem>
              </ListWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockFiveImage alt="Порочный круг формируется следующим образом" />
            </ImageWrapper>
          </BlockContainer>

          <BlockContainer>
            <ImageWrapper>
              <BlockSixImage alt="Поведение закрепляется, по принципу подкрепления" />
            </ImageWrapper>
            <InfoContainer>
              <TextH3styles>
                Это поведение закрепляется, по принципу подкрепления, так как
                приносит облегчение
              </TextH3styles>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  То есть по сути механизм прост – любое поведение, которое
                  приносит пользу, закрепляется за счет частого повторения и тем
                  самым доводится до автоматического акта.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>

          <BlockContainer>
            <InfoContainer>
              <TextH3styles>
                И все бы ничего, если бы не первоначальная оценка, что ручка
                двери чрезвычайно опасна для здоровья
              </TextH3styles>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Представим себе ситуацию, что завтра по всем СМИ будут
                  обнародованы данные, подтверждающие на основе научных
                  исследований, что ручки двери несут угрозу для человечества. В
                  таком случае такое поведение как тщательное мытье рук после
                  контакта с ручкой всеми воспринималась бы как нормативное. Но
                  таких данных слава богу никто не публиковал, и мы спокойно в
                  течении дня можем многократно открывать и закрывать двери. Но
                  поведение людей с ОКР похоже на поведение, у которых как будто
                  эти данные есть. Соответственно, первоначальным пусковым
                  механизмом является оценка или интерпретация.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockSevenImage alt="Все бы ничего, если бы не первоначальная оценка" />
            </ImageWrapper>
          </BlockContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
