import React from 'react'
import listItemEightIcon from '../../../../static/img/KognitivniiPsiholog/list-item-8.svg'
import listItemFiveIcon from '../../../../static/img/KognitivniiPsiholog/list-item-5.svg'
import listItemFourIcon from '../../../../static/img/KognitivniiPsiholog/list-item-4.svg'
import listItemNineIcon from '../../../../static/img/KognitivniiPsiholog/list-item-9.svg'
import listItemOneIcon from '../../../../static/img/KognitivniiPsiholog/list-item-1.svg'
import listItemSevenIcon from '../../../../static/img/KognitivniiPsiholog/list-item-7.svg'
import listItemSixIcon from '../../../../static/img/KognitivniiPsiholog/list-item-6.svg'
import listItemThreeIcon from '../../../../static/img/KognitivniiPsiholog/list-item-3.svg'
import listItemTwoIcon from '../../../../static/img/KognitivniiPsiholog/list-item-2.svg'

import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './KognitivniiOnlineYouTalk.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const KognitivniiOnlineYouTalk = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Когнитивно поведенческая терапия онлайн в YouTalk
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Об эффективности когнитивно-поведенческой психотерапии
                свидетельствуют большой мета-анализ из обзора более чем 250
                исследований, подтверждающих положительную динамику в лечении
                ментальных расстройств.
              </Text.Large>
              <Text.Large semiBold>
                На основании множества исследований результатов терапии в разных
                точках мира в независимых исследованиях установлена
                эффективность при работе с:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={listItemOneIcon} />
              <Text.Large semiBold>
                Посттравматическим стрессовым расстройством (ПТСР)
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemTwoIcon} />
              <Text.Large semiBold>
                Обсессивно-компульсивным расстройством (ОКР)
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemThreeIcon} />
              <Text.Large semiBold>
                Тревожными расстройствами (специфическими и неспецифическими
                фобиями, паническим расстройством, генерализованной
                тревожностью)
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemFourIcon} />
              <Text.Large semiBold>
                Расстройствами пищевого поведения (РПП)
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemFiveIcon} />
              <Text.Large semiBold>
                Соматическими расстройствами (высокое давление, боли в спине,
                синдром хронической усталости и др.)
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemSixIcon} />
              <Text.Large semiBold>Нарушениями сна (инсомнии)</Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemSevenIcon} />
              <Text.Large semiBold>
                Утратой близкого человека, развод или длительная разлука
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemEightIcon} />
              <Text.Large semiBold>Нарушениями самооценки</Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={listItemNineIcon} />
              <Text.Large semiBold>В групповой работе</Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
