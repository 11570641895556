import React from 'react'
import {
  BlockContainer,
  ButtonsWrapper,
  Content,
  H1asH2styles,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  TextH1styles,
  Wrapper
} from './HeroBlock.styles'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const HeroBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextH1styles>Найдите своего психолога</TextH1styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  YouTalk подберет опытных специалистов по КПТ, а с кем начать
                  работу — решаете вы
                </Text.Large>
                <SeoPriceForTitleBlock />
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Найти психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <HeroImage alt="Записаться к онлайн терапевту для лечения депрессии" />
            </ImageWrapper>
          </BlockContainer>
          <InfoContainer>
            <TitleWrapper>
              <H1asH2styles>
                Онлайн консультации когнитивного терапевта
              </H1asH2styles>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Когнитивно-поведенческий психолог помогает разорвать порочный
                круг проблем, выстроить новые нейронные связи и по-новому
                взглянуть на беспокоящую ситуацию. А подобрать такого
                специалиста помогает YouTalk.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
