import React from 'react'
import {
  BlockImage,
  ButtonsWrapper,
  Content,
  ImageWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  Wrapper
} from './FindYourSpecialist.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'
import { TitleWrapper } from '../Common.styles'

export const FindYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите психолога для консультации прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <ListWrapper>
              <ListItem>
                <Text.Large semiBold>
                  Вы назначаете сессию в удобное время
                </Text.Large>
              </ListItem>
              <ListItem>
                <Text.Large semiBold>
                  Экономите время на дорогу до кабинета и обратно
                </Text.Large>
              </ListItem>
              <ListItem>
                <Text.Large semiBold>
                  Работаете с психологом в комфортной и привычной обстановке
                </Text.Large>
              </ListItem>
              <ListItem>
                <Text.Large semiBold>
                  Можете бесплатно отменить или перенести сессию за 24 часа
                </Text.Large>
              </ListItem>
            </ListWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Подберите психолога и запишитесь на консультацию прямо сейчас!" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
