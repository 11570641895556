import React from 'react'
import {
  BlockContainer,
  BlockOneImage,
  BlockThreeImage,
  BlockTwoImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TextH3styles,
  Wrapper
} from './DescriptionKognitivnii.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const DescriptionKognitivnii = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextH3styles>
                  Например, человек пришел на собеседование, чтобы устроиться на
                  работу
                </TextH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  И у него в процессе возникает автоматическая мысль: «Если я не
                  получу эту работу — это будет полный провал» или «Если я не
                  получу эту работу, я очередной раз увижу, что являюсь
                  абсолютным неудачником»
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockOneImage alt="Человек пришел на собеседование, чтобы устроиться на работу" />
            </ImageWrapper>
          </BlockContainer>
          <BlockContainer>
            <ImageWrapper>
              <BlockTwoImage alt="Причин для любого решения работодателя может быть большое множество" />
            </ImageWrapper>
            <InfoContainer>
              <TitleWrapper>
                <TextH3styles>
                  При этом объективно причин для любого решения работодателя
                  может быть большое множество
                </TextH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Например, его отказ вызван тем, что он ищет узкого
                  специалиста, или определенного возраста, или специалиста
                  готового к частым командировкам и т.д. Но сам характер этих
                  автоматических мыслей создает определенное искажение
                  реальности, в котором все эти объективные причины игнорируются
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
          </BlockContainer>
          <BlockContainer>
            <InfoContainer>
              <TitleWrapper>
                <TextH3styles>
                  Такие мысли-искажения приводят к тому, что человек всё время
                  сталкивается с похожими трудностями
                </TextH3styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Которые лишают его веры в себя, энергии и способности к
                  изменению поведения. В конечном итоге это может приводить к
                  депрессии, тревожности и другим проблемам.{' '}
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ImageWrapper>
              <BlockThreeImage alt="Человек всё время сталкивается с похожими трудностями" />
            </ImageWrapper>
          </BlockContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
