import React from 'react'
import { Button } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  CardContainer,
  ChatImage,
  Content,
  TextWrapper,
  TitleSection,
  Wrapper
} from './KognitivniiQueries.styles'
import { Event as GAEvent } from '../../../components/GA'

export const KognitivniiQueries = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <ChatImage />
          <TitleSection>
            В YouTalk вы можете выбрать когнитивно-поведенческого психолога,
            работающего конкретно с вашим запросом.
          </TitleSection>
          <TextWrapper>
            Также можно указать желаемый пол и возраст специалиста.
          </TextWrapper>
        </CardContainer>
        <ButtonsWrapper>
          <Button.NewPrimary
            href="/wizard/"
            onClick={GAEvent.openSemeinyiPageFirstScreen}
            size="large"
            type="link"
          >
            Подобрать психолога
          </Button.NewPrimary>
        </ButtonsWrapper>
      </Content>
    </section>
  </Wrapper>
)
