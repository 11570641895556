import cardFiveIcon from '../../../../static/img/KognitivniiPsiholog/kognitivniiCards-five.svg'
import cardFourIcon from '../../../../static/img/KognitivniiPsiholog/kognitivniiCards-four.svg'
import cardOneIcon from '../../../../static/img/KognitivniiPsiholog/kognitivniiCards-one.svg'
import cardThreeIcon from '../../../../static/img/KognitivniiPsiholog/kognitivniiCards-three.svg'
import cardTwoIcon from '../../../../static/img/KognitivniiPsiholog/kognitivniiCards-two.svg'
import styled from 'styled-components'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`
export const TitleSection = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const CardThreeImage = styled.img.attrs(() => ({
  src: cardThreeIcon
}))``

export const CardFourImage = styled.img.attrs(() => ({
  src: cardFourIcon
}))``

export const CardFiveImage = styled.img.attrs(() => ({
  src: cardFiveIcon
}))``

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 64px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
  ${TitleSection} {
    color: ${(props) => props.color ?? '#333'};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const CustomGrid = styled(Grid)`
  padding-left: 10px;
  padding-right: 10px;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 64px;
  row-gap: 64px;
  margin-bottom: 64px;
  justify-content: center;

  @media (max-width: ${size.lg}) {
    row-gap: 24px;
    margin-bottom: 32px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`

export const Card = styled.div`
  max-width: 314px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  @media (max-width: ${size.lg}) {
    max-width: 277.33px;
  }

  @media (max-width: ${size.md}) {
    max-width: 200px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }

  ${Text.Large} {
    color: #fff;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
