import React from 'react'
import {
  CardContainer,
  Content,
  EarthImage,
  TextWrapper,
  Wrapper
} from './KognitivniiOnline.styles'
import { Title } from '../../../../youtalk-storybook/src/ui'

export const KognitivniiOnline = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <EarthImage />
          <Title.H2>Когнитивный психолог онлайн из любой точки Мира</Title.H2>
          <TextWrapper>
            Заниматься с когнитивно-поведенческим психологом можно из любой
            страны благодаря онлайн-сервису психологической помощи YouTalk. Вы
            можете подобрать терапевта для работы, исходя из удобного времени,
            бюджета и конкретной проблемы, с которой вам нужна помощь{' '}
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
