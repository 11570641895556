import React from 'react'
import { Button, Text } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Card,
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardThreeImage,
  CardTwoImage,
  CardsContainer,
  Content,
  CustomGrid,
  ImageWrapper,
  TitleSection,
  TitleWrapper,
  Wrapper
} from './KognitivniiCards.styles'
import { Event as GAEvent } from '../../../components/GA'

const cardData = [
  {
    image: <CardOneImage />,
    text: 'Определить проблему и желаемый результат'
  },
  {
    image: <CardTwoImage />,
    text: 'Исследовать автоматические мысли с помощью разных методик, например, ведения дневника автоматических мыслей'
  },
  {
    image: <CardThreeImage />,
    text: 'Развить навыки самопомощи (различные техники мышечной релаксации, дыхательные техники, визуализации)'
  },
  {
    image: <CardFourImage />,
    text: 'Научиться ставить под сомнение тревожащие мысли'
  },
  {
    image: <CardFiveImage />,
    text: 'Научиться новым, более полезным моделям поведения'
  }
]

export const KognitivniiCards = () => (
  <Wrapper>
    <section>
      <CustomGrid>
        <Content>
          <TitleWrapper color="#fff">
            <TitleSection>Когнитивный психолог помогает</TitleSection>
          </TitleWrapper>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <Text.Large semiBold>{card.text}</Text.Large>
              </Card>
            ))}
          </CardsContainer>
          <ButtonsWrapper>
            <Button.NewPrimary
              href="/wizard/"
              onClick={GAEvent.openSemeinyiPageFirstScreen}
              size="large"
              type="link"
            >
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonsWrapper>
        </Content>
      </CustomGrid>
    </section>
  </Wrapper>
)
