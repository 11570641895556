import blockFiveIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fifthblock-image.svg'
import blockFiveIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fifthblock-image-1024.svg'
import blockFiveIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fifthblock-image-320.svg'
import blockFiveIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fifthblock-image-414.svg'
import blockFiveIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fifthblock-image-768.svg'
import blockFourIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fourthblock-image.svg'
import blockFourIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fourthblock-image-1024.svg'
import blockFourIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fourthblock-image-320.svg'
import blockFourIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fourthblock-image-414.svg'
import blockFourIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-fourthblock-image-768.svg'
import blockOneIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-firstblock-image.svg'
import blockOneIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-firstblock-image-1024.svg'
import blockOneIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-firstblock-image-320.svg'
import blockOneIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-firstblock-image-414.svg'
import blockOneIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-firstblock-image-768.svg'
import blockSevenIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-seventhblock-image.svg'
import blockSevenIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-seventhblock-image-1024.svg'
import blockSevenIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-seventhblock-image-320.svg'
import blockSevenIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-seventhblock-image-414.svg'
import blockSevenIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-seventhblock-image-768.svg'
import blockSixIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-sixthblock-image.svg'
import blockSixIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-sixthblock-image-1024.svg'
import blockSixIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-sixthblock-image-320.svg'
import blockSixIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-sixthblock-image-414.svg'
import blockSixIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-sixthblock-image-768.svg'
import blockThreeIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-thirdblock-image.svg'
import blockThreeIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-thirdblock-image-1024.svg'
import blockThreeIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-thirdblock-image-320.svg'
import blockThreeIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-thirdblock-image-414.svg'
import blockThreeIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-thirdblock-image-768.svg'
import blockTwoIcon from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-secondblock-image.svg'
import blockTwoIcon1024 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-secondblock-image-1024.svg'
import blockTwoIcon320 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-secondblock-image-320.svg'
import blockTwoIcon414 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-secondblock-image-414.svg'
import blockTwoIcon768 from '../../../../static/img/KognitivniiPsiholog/whoCanHelps-secondblock-image-768.svg'

import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    margin-bottom: 24px;

    &:nth-child(even) {
      flex-direction: column-reverse;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const Br = styled.br`
  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const TextH3styles = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #333;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }

  & > span {
    color: #35b3a9;
  }
`

export const ListWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  padding-left: 25px;
  list-style-type: disc;
`
export const ListItem = styled.li`
  gap: 8px;
  color: #333;
`

export const BlockOneImage = styled.img.attrs(() => ({}))`
  content: url(${blockOneIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockOneIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockOneIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockOneIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockOneIcon320});
  }
`

export const BlockTwoImage = styled.img.attrs(() => ({}))`
  content: url(${blockTwoIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockTwoIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockTwoIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockTwoIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockTwoIcon320});
  }
`

export const BlockThreeImage = styled.img.attrs(() => ({}))`
  content: url(${blockThreeIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockThreeIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockThreeIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockThreeIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockThreeIcon320});
  }
`

export const BlockFourImage = styled.img.attrs(() => ({}))`
  content: url(${blockFourIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockFourIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockFourIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockFourIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockFourIcon320});
  }
`

export const BlockFiveImage = styled.img.attrs(() => ({}))`
  content: url(${blockFiveIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockFiveIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockFiveIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockFiveIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockFiveIcon320});
  }
`

export const BlockSixImage = styled.img.attrs(() => ({}))`
  content: url(${blockSixIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockSixIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockSixIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockSixIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockSixIcon320});
  }
`

export const BlockSevenImage = styled.img.attrs(() => ({}))`
  content: url(${blockSevenIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${blockSevenIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    height: 341px;
    width: 100%;
    content: url(${blockSevenIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockSevenIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockSevenIcon320});
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const Wrapper = styled.div`
  background-color: #fff;
`
